import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 100px 0 100px;
  @media only screen and (max-width: 990px) {
    padding: 80px 0 80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0 50px;
  }
`

export const ContentWrapper = styled.div`
  padding: 80px 80px;
  background-color: ${themeGet("colors.lightGrey")};
  @media only screen and (max-width: 1220px) {
    padding: 80px 60px 80px;
  }
  @media only screen and (max-width: 990px) {
    padding: 80px 60px 80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 30px 30px;
  }
`

export const Header = styled.div`
  width: 100%;
  .category {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 8px;
  }
  .title {
    font-weight: 700;
    font-size: 40px;
    color: ${themeGet("colors.black")};
    @media only screen and (max-width: 990px) {
      font-size: 32px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 26px;
    }
  }
  .seperator {
    border-top: 1px solid ${themeGet("colors.mainBlue")};
    margin: 20px 0;
    width: 40px;
    margin-left: 0;
    @media only screen and (max-width: 480px) {
      margin: 16px 0;
    }
  }
`

export const MainContent = styled.div`
  column-count: 2;
  column-gap: 120px;
  @media only screen and (max-width: 990px) {
    column-count: 1;
  }
  ul {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 40px;
    @media only screen and (max-width: 990px) {
      font-size: 20px;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  li {
    margin: 14px 0 10px;
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 26px;
    text-indent: -26px;
    @media only screen and (max-width: 990px) {
      margin: 8px;
    }
  }

  p {
    font-weight: 300;
    line-height: 27px;
    font-size: 18px;
    color: ${themeGet("colors.black")};
    margin-top: 0px;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
`

export default SectionWrapper
