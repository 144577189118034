import SectionWrapper, {
  ContentWrapper,
  Header,
  MainContent,
} from "./jobDescription.style"

import React from "react"
import striptags from "striptags"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import Container from "../../components/UI/Container"

const JobBoard = ({ content }) => {

  if (!content) return null

  const { title, subtitle, job_description } = content.items[0]

  return (
    <SectionWrapper>
      <Container width="1220px">
        <ContentWrapper>
          <Header>
            <Text content={subtitle} className="category" />
            <Heading
              as={title.html.substring(1, 3)}
              content={striptags(title.html)}
              {...title}
              className="title"
            />
            <hr className="seperator" />
          </Header>
          <MainContent
            dangerouslySetInnerHTML={{ __html: job_description.html }}
            className="content"
          />
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default JobBoard
