import React from "react"

import Button from "../../components/Button"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import Container from "../../components/UI/Container"
import SectionWrapper, {
  Content,
  DetailsInner,
  JoinButton,
} from "./joinUs.style"

const JoinUs = () => {
  return (
    <SectionWrapper>
      <Container width="1220px" className="container_wrapper">
        <DetailsInner>
          <Content>
            <Heading as="h2" content="Join us" />
            <Text content="Сhoose the position you want to apply and send us your CV. We will contact you within 1-3 business days." />
          </Content>
          <JoinButton>
            <a href="mailto: hr@alpacked.io">
              <Button className="primary" title="hr@alpacked.io" />
            </a>
          </JoinButton>
        </DetailsInner>
      </Container>
    </SectionWrapper>
  )
}

export default JoinUs
