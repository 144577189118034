import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Banner from "../containers/Banner"
import Breadcrumbs from "../containers/Breadcrumbs"
import JobDescription from "../containers/JobDescription"
import JoinUs from "../containers/JoinUs"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"

const CareersPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query Careers {
      prismicCareers {
        data {
          seo_title {
            text
          }
          seo_short_description {
            text
          }
          seo_description {
            text
          }
          image_link_preview {
            fluid {
              src
            }
          }
          body {
            ... on PrismicCareersBodyBanner {
              id
              items {
                title {
                  html
                }
                subtitle
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 72) {
                        src
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicCareersBodyJobDescription {
              id
              items {
                title {
                  html
                }
                subtitle
                job_description {
                  html
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    body,
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
  } = data.prismicCareers.data

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/careers">
        <ContentWrapper>
          <Banner
            title={body[0].items[0].title.html}
            subtitle={body[0].items[0].subtitle}
            image={body[0].items[0].image.localFile.childImageSharp.fluid.src}
          />
          <Breadcrumbs page="careers" />
          <JobDescription content={body[1]} />
          <JoinUs />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default CareersPage
